import { render, staticRenderFns } from "./video.vue?vue&type=template&id=9e9ff640&scoped=true"
import script from "./video.vue?vue&type=script&lang=js"
export * from "./video.vue?vue&type=script&lang=js"
import style0 from "./video.vue?vue&type=style&index=0&id=9e9ff640&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9e9ff640",
  null
  
)

export default component.exports