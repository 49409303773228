<template>
  <b-container fluid="true">
    <!-- Reports Interface controls -->
    <h5 class="my-3"><strong>REPORTS BY VIDEO</strong></h5>
    <a-row :gutter="[16, 4]">
      <a-col :xl="6" :md="12" :xs="24" class="custom-report-input-wrapper">
        <a-select mode="multiple" allowClear placeholder="Advertisers" :showSearch="true" :filterOption="true"
          optionFilterProp="children" v-model="filteredColumns.selectedAdvertisers.Values">
          <a-select-option v-for="obj in advertiser.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}</a-select-option>
        </a-select>
        <a-button @click="addAdvertisers"><i class="fa fa-user"></i></a-button>
      </a-col>
      <a-col :xl="6" :md="12" :xs="24" class="custom-report-input-wrapper">
        <a-select mode="multiple" allowClear placeholder="Campaigns" :showSearch="true" :filterOption="true"
          optionFilterProp="children" v-model="filteredColumns.selectedCampaigns.Values">
          <a-select-option v-for="obj in ortbCampaign.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}</a-select-option>
        </a-select>
        <a-button @click="addCampaigns"><i class="fa fa-user"></i></a-button>
      </a-col>
      <!-- <a-col :xl="6" :md="12" :xs="24">
        <a-select mode="multiple" allowClear placeholder="Video Ads" style="width: 100%" :showSearch="true"
          :filterOption="true" optionFilterProp="children" v-model="filteredColumns.selectedVideoAds.Values">
          <a-select-option v-for="obj in videoad.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}
          </a-select-option>
        </a-select>
      </a-col> -->
      <a-col :xl="6" :md="12" :xs="24">
        <a-select mode="multiple" allowClear placeholder="Remote RTB Zones" style="width: 100%" :showSearch="true"
          :filterOption="true" optionFilterProp="children" v-model="filteredColumns.selectedRemotertbZones.Values">
          <a-select-option v-for="obj in remotertbzone.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}
          </a-select-option>
        </a-select>
      </a-col>
      <a-col :xl="6" :md="12" :xs="24">
        <a-select mode="multiple" allowClear placeholder="Remote Video Zones" style="width: 100%" :showSearch="true"
          :filterOption="true" optionFilterProp="children" v-model="filteredColumns.selectedRemotevideoZones.Values">
          <a-select-option v-for="obj in remotevideozone.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name
          }}</a-select-option>
        </a-select>
      </a-col>
    </a-row>

    <a-row :gutter="[16, 4]">
      <a-col :xl="6" :md="12" :xs="24" class="custom-report-input-wrapper">
        <a-select mode="multiple" allowClear placeholder="Publishers" :showSearch="true" :filterOption="true"
          optionFilterProp="children" v-model="filteredColumns.selectedPublishers.Values">
          <a-select-option v-for="obj in ORTBpublisher.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}</a-select-option>
        </a-select>
        <a-button @click="addPublishers"><i class="fa fa-user"></i></a-button>
      </a-col>
      <a-col :xl="6" :md="12" :xs="24">
        <a-select mode="multiple" allowClear placeholder="Video Zones" style="width: 100%" :showSearch="true"
          :filterOption="true" optionFilterProp="children" v-model="filteredColumns.selectedVideoZones.Values">
          <a-select-option v-for="obj in videozone.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :xl="6" :md="12" :xs="24">
        <a-select mode="multiple" allowClear placeholder="RTB Zones" style="width: 100%" :showSearch="true"
          :filterOption="true" optionFilterProp="children" v-model="filteredColumns.selectedRtbZones.Values">
          <a-select-option v-for="obj in rtbzone.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :xl="6" :md="12" :xs="24">
        <a-select mode="multiple" allowClear placeholder="Creatives" style="width: 100%" :showSearch="true"
          :filterOption="true" optionFilterProp="children" v-model="filteredColumns.selectedCreatives.Values">
          <a-select-option v-for="obj in creative.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}</a-select-option>
        </a-select>
      </a-col>
    </a-row>

    <a-row :gutter="[16, 4]">
      <a-col :xl="6" :md="12" :xs="24">
        <a-select mode="multiple" allowClear placeholder="Overlays" style="width: 100%" :showSearch="true"
          :filterOption="true" optionFilterProp="children" v-model="filteredColumns.selectedOverlays.Values">
          <a-select-option v-for="obj in overlay.list" :key="obj.Id">[{{ obj.Id }}]{{ obj.Name }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :xl="6" :md="12" :xs="24">
        <a-select mode="multiple" allowClear placeholder="Companions" style="width: 100%" :showSearch="true"
          :filterOption="true" optionFilterProp="children" v-model="filteredColumns.selectedCompanions.Values">
          <a-select-option v-for="obj in companion.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :xl="6" :md="12" :xs="24">
        <a-select mode="multiple" allowClear placeholder="Icons" style="width: 100%" :showSearch="true"
          :filterOption="true" optionFilterProp="children" v-model="filteredColumns.selectedIcons.Values">
          <a-select-option v-for="obj in icon.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :xl="6" :md="12" :xs="24">
        <a-select mode="multiple" allowClear placeholder="Countries" style="width: 100%" :showSearch="true"
          :filterOption="countryFilter" optionFilterProp="children" v-model="filteredColumns.selectedCountries.Values">
          <!-- <a-select-option v-for="obj in info.countries" :key="obj.CC">{{obj.Country}}</a-select-option> -->
          <a-select-option v-for="obj in info.countries" :key="obj.CC"><img :src="matchIcon(countries, obj.CC)" /> {{
            obj.Country
          }}</a-select-option>
        </a-select>
      </a-col>
    </a-row>

    <a-row :gutter="[16, 4]">
      <a-col :xl="6" :md="12" :xs="24">
        <a-select mode="multiple" allowClear placeholder="Operating Systems" style="width: 100%" :showSearch="true"
          :filterOption="countryFilter" optionFilterProp="children" v-model="filteredColumns.selectedOs.Values">
          <!-- <a-select-option v-for="obj in info.countries" :key="obj.CC">{{obj.Country}}</a-select-option> -->
          <a-select-option v-for="obj in info.oss" :key="obj.Family"> {{ obj.Family }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :xl="6" :md="12" :xs="24">
        <a-select mode="multiple" allowClear placeholder="Browsers" style="width: 100%" :showSearch="true"
          :filterOption="countryFilter" optionFilterProp="children" v-model="filteredColumns.selectedBrowsers.Values">
          <!-- <a-select-option v-for="obj in info.countries" :key="obj.CC">{{obj.Country}}</a-select-option> -->
          <a-select-option v-for="obj in info.browsers" :key="obj.Family"> {{ obj.Family }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :xl="6" :md="12" :xs="24">
        <div style="display: flex; justify-content: space-between">
          <a-range-picker :defaultValue="this.ranges['Today']" @change="setDateRange" :ranges="ranges" :allowClear="false"
            style="width: 70%" />
          <a-button type="primary" class="ml-2" @click="setFilters" style="width: 30%">
            Apply
          </a-button>
          <b-button size="sm" class="btn-info mr-1" @click="exportToCsv" title="Export to CSV" style="margin-left: 6px">
            <i class="fa fa-file-excel-o"></i>
          </b-button>
        </div>
      </a-col>
    </a-row>
    <b-row class="mt-2">
      <b-col md="6" class="my-1">
        <b-form-group label-cols-sm="2" label="Per page:" class="mb-0">
          <perPageSelect v-model="perPage" :storageKey="'ortb-reports-video'" :pageOptions="pageOptions" />
          <!-- <b-form-select v-model="perPage" :options="pageOptions" class="form-control ml-0 w-25"></b-form-select> -->
          <columnSelect :storageKey="'display-reports-video-columns'" :options="fields"></columnSelect>
        </b-form-group>
      </b-col>

      <!--<b-col md="6" class="my-1 d-flex flex-row-reverse">
          <b-form-group class="mb-0">
            <b-input-group>
              <b-form-input v-model="filter" class="form-control w-50" placeholder="Type to Search"></b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>-->
    </b-row>

    <!-- Main table element -->
    <b-table @sort-changed="sortingChanged" ref="table" show-empty :sort-direction="sortDirection" :small="true"
      :bordered="true" :responsive="true" :striped="true" :hover="true" stacked="md" :items="getTableData"
      :fields="filteredFields" :current-page="currentPage" :per-page="perPage" :no-local-sorting="true"
      @filtered="onFiltered" :busy="isBusy">
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle" variant="primary"></b-spinner>
        </div>
      </template>
      <template #cell(CC)="data">
        <div><img :src="matchIcon(countries, data.item.CC)" /> {{ data.item.CC }}</div>
      </template>
      <template #cell(Coverage)="data">
        <div v-if="data.item.Coverage">{{ (Math.round(data.item.Coverage * 100) / 100).toFixed(2) }}%</div>
      </template>
      <template #cell(RemotertbzoneId)="data">
        <router-link v-if="data.item.RemotertbzoneId && data.item.RemotertbzoneId !== 'N/A'" :to="{ name: 'remotertbzone-update', params: { id: data.item.RemotertbzoneId } }"
          target="_blank" class="table-cursor-pointer">
          {{ data.item.RemotertbzoneId }}
        </router-link>
        <div v-else>
          {{ data.item.RemotertbzoneId }}
        </div>
      </template>
      <template #cell(RemotertbzoneName)="data">
        <router-link v-if="data.item.RemotertbzoneId && data.item.RemotertbzoneId !== 'N/A'"
          :to="{ name: 'remotertbzone-update', params: { id: data.item.RemotertbzoneId } }" target="_blank"
          class="table-cursor-pointer">
          {{ data.item.RemotertbzoneName }}
        </router-link>
        <div v-else>{{ data.item.RemotertbzoneName }}</div>
      </template>
      <template #cell(RemotevideozoneId)="data">
        <router-link v-if="data.item.RemotevideozoneId && data.item.RemotevideozoneId !== 'N/A'" :to="{ name: 'remotevideozone-update', params: { id: data.item.RemotevideozoneId } }"
          target="_blank" class="table-cursor-pointer">
          {{ data.item.RemotevideozoneId }}
        </router-link>
        <div v-else>
          {{ data.item.RemotevideozoneId }}
        </div>
      </template>
      <template #cell(RemotevideozoneName)="data">
        <router-link v-if="data.item.RemotevideozoneId && data.item.RemotevideozoneId !== 'N/A'"
          :to="{ name: 'remotevideozone-update', params: { id: data.item.RemotevideozoneId } }" target="_blank"
          class="table-cursor-pointer">
          {{ data.item.RemotevideozoneName }}
        </router-link>
        <div v-else>{{ data.item.RemotevideozoneName }}</div>
      </template>
      <template #cell(VideozoneId)="data">
        <router-link v-if="data.item.VideozoneId && data.item.VideozoneId !== 'N/A'" :to="{ name: 'videozone-update', params: { id: data.item.VideozoneId } }"
          target="_blank" class="table-cursor-pointer">
          {{ data.item.VideozoneId }}
        </router-link>
        <div v-else>
          {{ data.item.VideozoneId }}
        </div>
      </template>
      <template #cell(VideozoneName)="data">
        <router-link v-if="data.item.VideozoneId && data.item.VideozoneId !== 'N/A'"
          :to="{ name: 'videozone-update', params: { id: data.item.VideozoneId } }" target="_blank"
          class="table-cursor-pointer">
          {{ data.item.VideozoneName }}
        </router-link>
        <div v-else>{{ data.item.VideozoneName }}</div>
      </template>
      <template #cell(RtbzoneId)="data">
        <router-link v-if="data.item.RtbzoneId && data.item.RtbzoneId !== 'N/A'" :to="{ name: 'rtbzone-update', params: { id: data.item.RtbzoneId } }"
          target="_blank" class="table-cursor-pointer">
          {{ data.item.RtbzoneId }}
        </router-link>
        <div v-else>
          {{ data.item.RtbzoneId }}
        </div>
      </template>
      <template #cell(RtbzoneName)="data">
        <router-link v-if="data.item.RtbzoneId && data.item.RtbzoneId !== 'N/A'"
          :to="{ name: 'rtbzone-update', params: { id: data.item.RtbzoneId } }" target="_blank"
          class="table-cursor-pointer">
          {{ data.item.RtbzoneName }}
        </router-link>
        <div v-else>{{ data.item.RtbzoneName }}</div>
      </template>
      <template v-if="!loading" #custom-foot>
        <b-tr>
          <b-td v-for="col in filteredFields" :key="col.key">
            <div v-if="col.summed">
              <p v-if="col.formatter === '$formatNumber'" class="text-right mb-0 font-weight-bold">
                {{ Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(sum[col.key]) }}
              </p>
              <p v-else class="text-right mb-0 font-weight-bold">
                {{ Intl.NumberFormat('en-US', {
                  style: 'currency', currency: 'USD',
                  maximumFractionDigits: 2
                }).format(sum[col.key])
                }}
              </p>
            </div>
          </b-td>
        </b-tr>
      </template>
    </b-table>
    <b-row>
      <b-col md="6" class="my-1">
        <p>Showing {{ ((currentPage - 1) * perPage).toLocaleString() }} to {{
          (((currentPage - 1) * perPage) + perPage > totalRows ? totalRows : ((currentPage - 1) * perPage) +
            perPage)
        }} of {{ parseInt(totalRows).toLocaleString() }} entries</p>
      </b-col>
      <b-col md="6" class="my-1 d-flex flex-row-reverse">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0">
        </b-pagination>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import columnSelect from '@/components/custom/tableColumnSelect'
import perPageSelect from '@/components/custom/PerPageSelect'
import { getData, exportData } from '../../../api/display/reports_video'
import { mapState, mapGetters } from 'vuex'
import videoColumns from './columns/videoFields'
import moment from 'moment'
import staticCountryData from '@/resources/countries.json'
export default {
  name: 'reports',
  components: { columnSelect, perPageSelect },
  data() {
    return {
      countries: staticCountryData,
      sum: {},
      sortDirection: 'desc',
      userId: this.$store.state.auth.id,
      ranges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, 'day'), moment().subtract(1, 'day')],
        'This Week': [moment().startOf('isoWeek'), moment()],
        'Last Week': [moment().subtract(1, 'week').startOf('isoWeek'),
          moment().subtract(1, 'week').endOf('isoWeek')],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'),
          moment().subtract(1, 'month').endOf('month')],
      },
      dateRange: {
        Column: 'DateStats',
        From: '',
        To: '',
        Values: [],
      },
      filteredColumns: {
        selectedAdvertisers: {
          Column: 'AdvertiserId',
          Values: [],
        },
        selectedCampaigns: {
          Column: 'CampaignId',
          Values: [],
        },
        // selectedVideoAds: {
        //   Column: 'VideoadId',
        //   Values: [],
        // },
        selectedRemotertbZones: {
          Column: 'RemotertbzoneId',
          Values: [],
        },
        selectedRemotevideoZones: {
          Column: 'RemotevideozoneId',
          Values: [],
        },
        selectedPublishers: {
          Column: 'PublisherId',
          Values: [],
        },
        selectedVideoZones: {
          Column: 'VideozoneId',
          Values: [],
        },
        selectedRtbZones: {
          Column: 'RtbzoneId',
          Values: [],
        },
        selectedCreatives: {
          Column: 'CreativeId',
          Values: [],
        },
        selectedOverlays: {
          Column: 'OverlayId',
          Values: [],
        },
        selectedCompanions: {
          Column: 'CompanionId',
          Values: [],
        },
        selectedIcons: {
          Column: 'IconId',
          Values: [],
        },
        selectedCountries: {
          Column: 'CC',
          Values: [],
        },
        selectedOs: {
          Column: 'OsFamily',
          Values: [],
        },
        selectedBrowsers: {
          Column: 'BrowserFamily',
          Values: [],
        },
      },
      loading: false,
      isBusy: false,
      tableData: [],
      fields: videoColumns,
      filters: [],
      currentPage: 1,
      perPage: 50,
      pageOptions: [20, 50, 100, 200],
      order: [],
      total: 0,
    }
  },
  created() {
    this.$store.dispatch('videozone/LOAD_VIDEOZONES_SHORT')
    this.$store.dispatch('rtbzone/LOAD_RTBZONES_SHORT')
    this.$store.dispatch('ORTBpublisher/LOAD_PUBLISHERS_SHORT')
    this.$store.dispatch('advertiser/LOAD_ADVERTISERS_SHORT')
    this.$store.dispatch('ortbCampaign/LOAD_CAMPAIGNS_SHORT')
    this.$store.dispatch('videoad/LOAD_ADS_SHORT')
    this.$store.dispatch('remotertbzone/LOAD_ZONES_SHORT')
    this.$store.dispatch('remotevideozone/LOAD_ZONES_SHORT')
    this.$store.dispatch('creative/LOAD_CREATIVES')
    this.$store.dispatch('overlay/LOAD_OVERLAYS')
    this.$store.dispatch('companion/LOAD_COMPANIONS')
    this.$store.dispatch('icon/LOAD_ICONS')
    this.$store.dispatch('info/LOAD_COUNTRIES')
    this.$store.dispatch('info/LOAD_OSS')
    this.$store.dispatch('info/LOAD_BROWSERS')
    this.setDateRange(this.ranges.Today)
  },
  computed: {
    filteredFields() {
      return this.fields.filter(c => c.show)
    },
    totalRows() {
      return this.total
    },
    ...mapState(['ORTBpublisher', 'advertiser', 'ortbCampaign', 'info', 'icon', 'companion', 'overlay', 'creative', 'bannerzone', 'remotevideozone', 'remotertbzone', 'videoad', 'videozone', 'rtbzone']),
    ...mapGetters('advertiser', ['getActiveAdvertisers']),
    ...mapGetters('ORTBpublisher', ['getActivePublishers']),
    ...mapGetters('ortbCampaign', ['getActiveORTBCampaigns']),
  },
  methods: {
    addPublishers() {
      this.filteredColumns.selectedPublishers.Values = []
      this.getActivePublishers.forEach(el => {
        if (el.AccManagerId === this.userId) {
          this.filteredColumns.selectedPublishers.Values.push(el.Id)
        }
      })
    },
    addAdvertisers() {
      this.filteredColumns.selectedAdvertisers.Values = []
      this.getActiveAdvertisers.forEach(el => {
        if (el.AccManagerId === this.userId) {
          this.filteredColumns.selectedAdvertisers.Values.push(el.Id)
        }
      })
    },
    addCampaigns() {
      this.filteredColumns.selectedCampaigns.Values = []
      this.ortbCampaign.list.forEach(el => {
        if (el.AccManagerId === this.userId && el.Status === 0) {
          this.filteredColumns.selectedCampaigns.Values.push(el.Id)
        }
      })
    },
    exportToCsv() {
      const columns = this.fields.filter(ele => ele.show).map(ele => ele.key)
      exportData(columns, this.filters, this.order, this.currentPage, this.perPage).then(response => {
        const blob = new Blob([response], { type: 'text/csv' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'reports_video_' + moment().format('YYYY-MM-DD') + '.csv'
        link.click()
        URL.revokeObjectURL(link.href)
      })
    },
    countryFilter(inputValue, option) {
      return option.componentOptions.children[1].text.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
    },
    matchIcon(array, code) {
      const cc = array.find(element => element.cc === code)
      if (cc === undefined) { return false } else {
        const image = cc.image
        return image
      }
    },
    setFilters() {
      const array = []
      for (const col in this.filteredColumns) {
        if (this.filteredColumns[col].Values.length !== 0) {
          array.push(this.filteredColumns[col])
        }
      }
      if (this.dateRange.From !== '' && this.dateRange.To !== '') {
        array.push(this.dateRange)
      }
      this.filters = array
      this.$refs.table.refresh()
    },
    setDateRange(date) {
      this.filters = this.filters.filter(ele => ele.Column !== 'Date')
      this.dateRange.From = date[0] ? date[0].format('YYYY-MM-DD') : ''
      this.dateRange.To = date[1] ? date[1].format('YYYY-MM-DD') : ''
      this.filters.push(this.dateRange)
    },
    sortingChanged(ctx) {
      this.order = []
      this.order.push({
        Column: ctx.sortBy,
        Ord: ctx.sortDesc ? 'DESC' : 'ASC',
      })
      // this.$refs.table.refresh()
    },
    getTableData(ctx, callback) {
      this.loading = true
      const columns = this.fields.filter(ele => ele.show).map(ele => ele.key)
      getData(this.currentPage, this.perPage, columns, this.filters, this.order).then(response => {
        this.tableData = response.Data
        this.sum = response.Sum
        this.total = response.Total
        this.loading = false
        callback(response.Data)
      })
      return null
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    callback(value) {
      return value
    },
  },
  watch: {
    fields: {
      handler(val) {
        this.$refs.table.refresh()
      },
      deep: true,
    },
  },
}
</script>

<style scoped>
.custom-report-input-wrapper {
  display: flex;
  justify-content: center;
}

.custom-report-input-wrapper>.ant-select {
  width: 100%;
  margin-right: 2px;
}
.custom-report-input-wrapper>.ant-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
}
</style>
